import React, { useEffect, useState } from "react";
import { Tabs, Input, Button, Radio } from "@arco-design/web-react";
import Select from "./components/Select";
import Meber from "./MeBer/Meber";
import Role from "./Role/Role";
import Depe from "./Depe/Depe";
import ContentHeader from "@/components/UI/ContentHeader";
import Btninvitemeber from "./components/ButonInviatal";
import { inject, observer } from "mobx-react";
import "./index.less";
import { useNavigate } from "react-router-dom";
const TabPane = Tabs.TabPane;
enum ActiveTabEnum {
  Member = "Member",
  Role = "Role",
  Depe = "Depe",
}
const Teamanagement = (props: any) => {
  const { team, teamover } = props;
  const to = useNavigate();
  useEffect(() => {
    if (!team.depedataon) {
      return;
    }
    setActiveTab(ActiveTabEnum.Depe as ActiveTabEnum.Member);
  }, [team.depeactive]);

  useEffect(() => {
    if (localStorage.getItem("UserStore")) {
      const arr = JSON.parse(localStorage.getItem("UserStore"));
      if (arr?.teamInfo?.team_user_role?.roles == null) {
        to("/");
      } else {
        if (
          arr?.teamInfo?.team_user_role?.roles.filter(
            (item) => item?.code == "superadmin",
          )
        ) {
          team.team_membet_uuidget_to();
          teamover.teme_package_to();
          team.team_department_findid_to("");
          team.handeselecttdata("data");
        } else {
          to("/");
        }
      }
    }
  }, []);

  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(
    ActiveTabEnum.Member,
  );

  const toActiveTabGetFc = (activeTab: ActiveTabEnum) => {
    const obj = {
      [ActiveTabEnum.Depe]: <Depe></Depe>,
      [ActiveTabEnum.Member]: <Meber></Meber>,
      [ActiveTabEnum.Role]: <Role></Role>,
    };
    return obj[activeTab];
  };

  return (
    <div className="contentl_Teamanagement">
      <div className="contentllright">
        <ContentHeader
          name="团队"
          subName={`当前团队已有成员 ${(teamover?.supertoaldata?.total - teamover?.supertoaldata?.supertotal) || 0} 位，当前还可邀请 ${teamover?.supertoaldata?.supertotal || 0} 位`}
          Lebtn={<Btninvitemeber></Btninvitemeber>}
        />
        <div className="Filtemember_contel_fap">
          <div className="Filtememberscontext">
            <div className="Filtememberscontexttabs">
              <Tabs
                activeTab={activeTab}
                onChange={(e) => {
                  setActiveTab(e as ActiveTabEnum.Member);
                }}>
                <TabPane key={ActiveTabEnum.Member} title="成员管理"></TabPane>
                <TabPane key={ActiveTabEnum.Role} title="角色管理"></TabPane>
                <TabPane key={ActiveTabEnum.Depe} title="部门管理"></TabPane>
              </Tabs>
            </div>
            <div className="Filtememberscontextselectbody">
              <Select actvie={activeTab}></Select>
            </div>
          </div>
        </div>

        <div className="Filtemembersbodyform">
          {/*  role角色     member 成员    depe 部门 */}
          {toActiveTabGetFc(activeTab)}
        </div>
      </div>
    </div>
  );
};
export default inject("team", "teamover")(observer(Teamanagement));
