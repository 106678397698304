import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Message, Select } from "@arco-design/web-react";
import {
  IconMinusCircle,
  IconPlayArrow,
  IconDelete,
  IconClose,
  IconCheck,
} from "@arco-design/web-react/icon";
import Memberdepartments from "../components/Memberdepartments";
import FormTabel from "../components/FormTabel";
import { inject, observer } from "mobx-react";
import invite from "@/assets/member/invite.png";
import depenull from "@/assets/member/depenull.png";
import Enbledelet from "@/assets/member/Enbledelet.png";

import delect from "@/assets/member/delect.png";
import disableto from "@/assets/member/disable.png";
import move from "@/assets/member/move.png";
import movemerber from "@/assets/member/movemerber.png";
import Modals from "@/components/UI/Modals/index";
import disable from "@/assets/member/disablemerber.png";
import memuy from "@/assets/member/memuyiq.png";
import "./index.less";
interface OptionInfo {
  child: {
    key: string;
  };
}
const FormItem = Form.Item;
const Norenderfalse = (props) => {
  const { team } = props;
  return (
    <>
      <div className="depe_yqmobal_null">
        <div>
          <img src={depenull} alt="" />
          <p className="depe_yqmobal_null_title">暂时无数据</p>
          <Button
            id="depe_yqmobal_null_button"
            onClick={() => {
              team.oponDepe();
            }}>
            去设置部门
          </Button>
        </div>
      </div>
    </>
  );
};
const BtnElement = (props: any) => {
  const { team } = props;
  return (
    <>
      {/* <Btninvitemeber team={team}></Btninvitemeber> */}
      <Btnmovemerber team={team}></Btnmovemerber>
      <BtnEnable team={team}></BtnEnable>
      <BtnDisable team={team}></BtnDisable>
      <BtnDelect team={team}></BtnDelect>
    </>
  );
};
const Btnmovemerber = (props: any) => {
  const [form] = Form.useForm();
  const { team } = props;
  const formRef = useRef(form);
  const Option = Select.Option;
  const [Btnmovemebervisible, setBtnmovemebervisible] = useState(false);
  const closeModal = () => {
    setBtnmovemebervisible(false);
  };

  const [optionsdepe, setoptionsdepe] = useState([]);
  const [seleData, setDataopt] = useState(null);
  const closeModalsubit = async () => {
    try {
      await formRef.current.validate();
      if (!seleData) return;
      team.team_merber_move_to({
        ids: team.selectmeberactive,
        new_department_id: Number(seleData),
      });
      setBtnmovemebervisible(false);
    } catch (e) {
      // console.error(e);
    }
  };
  useEffect(() => {
    formRef.current.resetFields();
  }, [Btnmovemebervisible]);
  useEffect(() => {
    setoptionsdepe(team.Treedepedata);
  }, [team.Treedepedata]);
  return (
    <>
      <Button
        style={{ width: "96px", height: "34px", padding: "0" }}
        icon={
          <img
            src={move}
            style={{
              width: "16px",
              height: "16px",
            }}
            alt=""
          />
        }
        className="imgbutton"
        type="outline"
        onClick={() => {
          if (team.selectmeberactive.length != 0) {
            setBtnmovemebervisible(true);
          } else {
            Message.info({
              content: "请选择要移动部门的成员!",
            });
          }
        }}>
        移动成员
      </Button>
      <Modals
        width={464}
        height={230}
        title="批量移动成员"
        titleIcon={
          <img
            src={movemerber}
            alt=""
            style={{ color: "blue", width: "26px", height: "26px" }}
          />
        }
        visible={Btnmovemebervisible}
        cancelBtn={<Button onClick={closeModal}>取消</Button>}
        subMiteBtn={
          <Button type="primary" onClick={closeModalsubit}>
            确定
          </Button>
        }
        closeIcon={<IconClose onClick={closeModal} />}>
        <div className="movemerber_modal_wearpper">
          <div className="movemerber_modal_bodydiv">
            {/* <p className="movemerber_modal_bodyp">移动至:</p> */}
            <Form ref={formRef} style={{ maxWidth: 500 }} autoComplete="off">
              <FormItem
                label="移动至:"
                field="optitselect"
                requiredSymbol={false}
                rules={[{ required: true, message: "请选择部门" }]}>
                <Select
                  placeholder="部门"
                  style={{ width: 220 }}
                  onChange={(e, a) => {
                    setDataopt((a as OptionInfo).child.key);
                  }}
                  notFoundContent={<Norenderfalse team={team}></Norenderfalse>}>
                  {optionsdepe.map((option) => (
                    <Option key={option.key} value={option.title}>
                      {option.title}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Form>
          </div>
        </div>
      </Modals>
    </>
  );
};
const BtnEnable = (props: any) => {
  const { team } = props;
  return (
    <>
      <Button
        type="outline"
        className="imgbutton"
        icon={
          <img
            src={Enbledelet}
            alt=""
            style={{ width: "16px", height: "16px" }}
          />
        }
        style={{
          border: "1px solid #666666",
          color: "#666666",
          width: "70px",
          height: "34px",
          padding: "0",
        }}
        onClick={() => {
          if (team.selectmeberactiverole) {
            return Message.info({ content: "管理员状态不可操作" });
          } else if (team.selectmeberactivestate) {
            return Message.info({
              content: "成员操作不当,检查所选择的成员状态",
            });
          }
          if (team.selectmeberactive.length != 0) {
            team.team_meber_batchstaus_to({
              ids: team.selectmeberactive,
              state: 3,
            });
          } else {
            Message.info({
              content: "请选择要启用的成员!",
            });
          }
        }}>
        启用
      </Button>
    </>
  );
};
const BtnDisable = (props: any) => {
  const { team } = props;
  const [BtnclickVisble, setBtnclickVisble] = useState(false);
  const Btnclick = () => {
    setBtnclickVisble(false);
  };
  const BtnCllckModal = () => {
    if (team.selectmeberactive.length != 0) {
      team.team_meber_batchstaus_to({
        ids: team.selectmeberactive,
        state: 4,
      });
    }
    setBtnclickVisble(false);
  };

  return (
    <>
      <Button
        type="outline"
        className="imgbutton"
        style={{
          border: "1px solid #666666",
          color: "#666666",
          width: "70px",
          height: "34px",
          padding: "0",
        }}
        icon={
          <img
            src={disableto}
            alt=""
            style={{ width: "16px", height: "16px" }}
          />
        }
        onClick={() => {
          if (team.selectmeberactiverole) {
            return Message.info({ content: "管理员不可停用" });
          } else if (team.selectmeberactivestate) {
            return Message.info({
              content: "成员操作不当,检查所选择的成员状态",
            });
          }
          if (team.selectmeberactive.length != 0) {
            setBtnclickVisble(true);
          } else {
            Message.info({
              content: "请选择要停用的成员!",
            });
          }
        }}>
        停用
      </Button>
      <Modals
        width={464}
        height={230}
        title="提示"
        titleIcon={
          <img src={disable} alt="" style={{ width: "26px", height: "26px" }} />
        }
        visible={BtnclickVisble}
        cancelBtn={<Button onClick={Btnclick}>取消</Button>}
        subMiteBtn={
          <Button type="primary" onClick={BtnCllckModal}>
            确认
          </Button>
        }
        closeIcon={<IconClose onClick={Btnclick} />}>
        <div className="disblae_modals">
          <div className="disblae_modals_body">
            <p className="disblae_modals_titeldis">确定停用账号？</p>
            <p className="disblae_modals_tit_wrapp">停用后不可登录此团队</p>
          </div>
        </div>
      </Modals>
    </>
  );
};
const BtnDelect = (props: any) => {
  const { team } = props;
  const [Btnclickdelect, setBtnclickdelect] = useState(false);
  const Btnclickdeleclt = () => {
    setBtnclickdelect(false);
  };
  const BtnCllckModaldelect = () => {
    team.team_member_delete_to({
      ids: team.selectmeberactive,
    });
    setBtnclickdelect(false);
  };
  return (
    <>
      <Button
        type="outline"
        className="imgbutton"
        icon={
          <img src={delect} alt="" style={{ width: "16px", height: "16px" }} />
        }
        style={{
          border: "1px solid #666666",
          color: "#666666",
          width: "70px",
          height: "34px",
          padding: "0",
        }}
        onClick={() => {
          if (team.selectmeberactiverole) {
            return Message.info({ content: "管理员不可删除" });
          }
          if (team.selectmeberactive.length != 0) {
            setBtnclickdelect(true);
          } else {
            Message.info({
              content: "请选择要删除的成员!",
            });
          }
        }}>
        删除
      </Button>
      <Modals
        width={464}
        height={230}
        title="提示"
        titleIcon={
          <img src={disable} alt="" style={{ width: "26px", height: "26px" }} />
        }
        visible={Btnclickdelect}
        cancelBtn={<Button onClick={Btnclickdeleclt}>取消</Button>}
        subMiteBtn={
          <Button type="primary" onClick={BtnCllckModaldelect}>
            确认
          </Button>
        }
        closeIcon={<IconClose onClick={Btnclickdeleclt} />}>
        <div className="disblae_modals">
          <div className="disblae_modals_body">
            <p className="disblae_modals_titeldis">确认删除账号?</p>
            <p className="disblae_modals_tit_wrapp">
              删除后不可登录此团队，且数据不可恢复
            </p>
          </div>
        </div>
      </Modals>
    </>
  );
};

const Meber = (props: any) => {
  const { team } = props;
  useEffect(() => {
    team.team_merber_search_to({
      page: 1,
      page_size: 10,
    });
  }, []);

  return (
    <>
      <div className="merber_body_contel">
        <div
          className="bodyleft"
          style={{
            marginRight: "25px",
          }}>
          <div className="tree">
            <Memberdepartments></Memberdepartments>
          </div>
        </div>
        <div className="bodyright">
          <div className="tabletop">
            <BtnElement team={team}></BtnElement>
          </div>
          <div className="tablebody">
            <FormTabel></FormTabel>
          </div>
        </div>
      </div>
    </>
  );
};
export default inject("team")(observer(Meber));
