import React, { useEffect, useState } from "react";
import operateop from "@/assets/member/operate.png";
import { Button, Input, Collapse } from "@arco-design/web-react";
import delect from "@/assets/member/delect.png";
import details from "@/assets/member/details.png";
import depeaddmobal from "@/assets/member/depeaddmobal.png";
import { Tooltip } from "@arco-design/web-react";
import Modals from "@/components/UI/Modals/index";
import disablemerber from "@/assets/member/disablemerber.png";
const CollapseItem = Collapse.Item;
import { IconClose, IconUser } from "@arco-design/web-react/icon";
import "../index.less";
import { Form } from "@arco-design/web-react";
import { observer, inject } from "mobx-react";
interface DetailsChildProps {
  name: string;
  team_id: string;
}
interface DetailsProps {
  total: string;
  uuid: number | Array<[]> | String;
  item: Array<DetailsChildProps>;
}
const FormItem = Form.Item;
const DepeOperate = (props: any) => {
  const [dataState, setdataState] = useState(false); //控制 弹窗 浮现
  const { data } = props;
  const { team } = props;

  const InfoActive = () => {
    const [item, setitem] = useState<any>([]);
    const [valuename, setvaleuname] = useState("");
    const [formref] = Form.useForm();
    useEffect(() => {
      console.log(data);
      team.team_department_iddele_to(data.id).then((res) => {
        setitem(res);
        setvaleuname(res.name);
        console.log(res.members);
      });
    }, []);

    const [visbldelete, setvisbldelete] = useState(false);
    const closedeleModal = () => {
      setvisbldelete(false);
    };

    const [visbldeilt, setvisbldeilt] = useState(false);
    const HandeoppenClick = () => {
      setvisbldeilt(!visbldeilt);
    };
    const detilcolse = () => {
      formref
        .validate({ validateOnly: true })
        .then(() => {
          if (valuename != item.name) {
            team.team_department_idput_to({
              id: Number(item.id),
              name: valuename,
            });
            setvisbldeilt(false);
          }
        })
        .catch((e) => {
          console.log(e.errors);
        });
    };
    return (
      <>
        <Button
          type="primary"
          className="popin_img"
          icon={
            <img
              src={details}
              alt=""
              style={{ width: "14px", height: "14px" }}
            />
          }
          onClick={() => setvisbldeilt(true)}>
          详情
        </Button>
        <Modals
          width={600}
          // height={230}
          title="部门详情"
          titleIcon={
            <img
              src={depeaddmobal}
              style={{ color: "blue", width: "26px", height: "26px" }}
            />
          }
          visible={visbldeilt}
          cancelBtn={
            <Button
              onClick={() => {
                setvisbldeilt(false);
              }}>
              取消
            </Button>
          }
          subMiteBtn={
            <Button
              type="primary"
              onClick={() => {
                detilcolse();
              }}>
              保存
            </Button>
          }
          closeIcon={<IconClose onClick={HandeoppenClick} />}>
          <div className="depo_modaldeil">
            <div className="depo_modaldeil_divin">
              <p>所属团队:</p>
              <Input
                style={{width: '100%'}}
                className="depo_modaldeil_input"
                placeholder="所属团队"
                value={item.team_name}
                disabled
              />
            </div>
            <div className="depo_modaldeil_divin">
              <p>部门名称:</p>
              <Form
                form={formref}
                initialValues={{ name: valuename }}
                autoComplete="off">
                <FormItem
                  field="name"
                  rules={[
                    {
                      validator: (value, cb) => {
                        if (!value) {
                          cb("部门名称不能为空");
                        } else if (value == item.name) {
                          cb("部门名称不能重复");
                        } else {
                          cb();
                        }
                      },
                    },
                  ]}>
                  <Input
                    style={{width: '540px'}}
                    className="depo_modaldeil_input"
                    placeholder="请输入部门名称"
                    value={valuename}
                    onChange={(e) => {
                      setvaleuname(e);
                    }}
                  />
                </FormItem>
              </Form>
            </div>
            {/* <div className="depo_modaldeil_divin"> */}
            <div>
              <p>部门人数:</p>
              <Input
                style={{ background: "#FAFAFA" }}
                className="depo_modaldeil_input"
                placeholder="部门人数"
                disabled
                value={item.total}
              />
            </div>
            <div className="depo_modaldeil_divin">
              <p>成员明细:</p>
              <Collapse defaultActiveKey={"members1"} className="depe_detail">
                <CollapseItem
                  header={`${item.name}(${item.total && JSON.stringify(item.members) != "[]" ? item.total : 0})`}
                  name="members1"
                  className="lllllllllooo">
                  {JSON.stringify(item) != "[]" &&
                    JSON.stringify(item.members) != "[]" &&
                    item.members.map((y, index) => {
                      return (
                        <div className="list-item" key={`mebers${index}`}>
                          <IconUser
                            style={{
                              width: "16px",
                              height: "16px",
                              marginRight: "3px",
                            }}
                          />
                          <span className="optitle">{y.member_name}-</span>
                          <span>{y.member_phone}</span>
                        </div>
                      );
                    })}
                </CollapseItem>
              </Collapse>
            </div>
          </div>
        </Modals>

        <Button
          type="primary"
          className="popin_img"
          icon={
            <img
              src={delect}
              alt=""
              style={{ width: "14px", height: "14px" }}
            />
          }
          onClick={() => {
            setvisbldelete(true);
          }}>
          删除
        </Button>
        <Modals
          width={464}
          height={230}
          title="提示"
          titleIcon={
            <img
              src={disablemerber}
              style={{ color: "blue", width: "26px", height: "26px" }}
            />
          }
          visible={visbldelete}
          cancelBtn={
            <Button
              style={item.total != 0 ? { display: "none" } : {}}
              type="primary"
              onClick={closedeleModal}>
              取消
            </Button>
          }
          subMiteBtn={
            <Button
              type="primary"
              onClick={() => {
                if (item.total == 0) {
                  team.team_department_iddelete_to(data.id);
                }
                setvisbldelete(false);
              }}>
              确定
            </Button>
          }
          closeIcon={<IconClose onClick={closedeleModal} />}>
          <div className="delemodal_pbox">
            {item.total != 0 ? (
              <>
                <p>无法删除</p>
                <p>请在成员管理中移除部门下所有成员，再尝试</p>
              </>
            ) : (
              <>
                <p>确认删除当前部门？</p>
                <p>删除后数据不可恢复</p>
              </>
            )}
          </div>
        </Modals>
      </>
    );
  };

  return (
    <div className="popinformationbody">
      <div className="popinformationbrem" style={{ position: "relative" }}>
        <Tooltip
          position="bl"
          trigger="click"
          className="popinformation"
          content={<InfoActive></InfoActive>}>
          <img
            src={operateop}
            alt=""
            onClick={() => {
              setdataState(!dataState);
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default inject("team")(observer(DepeOperate));
